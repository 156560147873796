import { Spin } from 'antd';
import React, { useState ,useEffect} from 'react';
import { Button } from 'react-bootstrap';
import MintedToken from './components/MintedTokenImage';
import MintingStats from './components/MintingStats';
import PausedSale from './components/PausedSale';
import { PublicSale } from './components/PublicSale';
import WhiteListSale from './components/WhiteListSale';
import { CHAIN_ID } from './constants';
import { checkIfWalletIsConnected, connectWallet, getCurrentChainId, getMintedTokens, getMintLimit, getTotalSupply, isPublicSaleActiveService, isUserWhiteListedService, isWhiteListSaleActiveService, mintToken, publicSaleMint, setupNetworkChangeHandler, setupNFTMintedEventListener, whiteListSaleMint } from './service';
import logo from './img/logo.svg';


const App = () => {


  const [currentAccount, setCurrentAccount] = useState("");
  const [mintedNFTData, setMintedNFTData] = useState([]);

  const [showContent, setShowContent] = useState(true);

  const [loading,setLoading] = useState(false);

  const [mintsFetchLoading, setFetchLoading]  = useState(false);

  const [pageError,setPageError] = useState(null); 


  const [isUserWhiteListed, setIsUserWhiteListed] = useState(false);

  const [isWhiteListSaleActive, setIsWhiteListSaleActive] = useState(false);

  const [isPublicSaleActive, setIsPublicSaleActive] = useState(false);


  const [isPaused, setIsPaused] = useState(false);

  const [totalSupply, setTotalSupply] = useState(0);

  const [mintingLimit,setMintingLimit] = useState(0);




  const nftMintedEventHandler = (from, tokenId) => {
    alert("Congratulations, you minted your nft, it takes 5-10 mins for the nft to get reflected in opensea ")
    
    setTimeout(() => {
      getMintedNFTs()
    },4)
  }


  const changeInNetworkEventHandler = (chainId) => {
    if(String(chainId)!= CHAIN_ID) {
      // some message cause user has changed network!
      setShowContent(false)
    }
  }


  const setupUser = async (account) => {
    try{
      await setupNetworkChangeHandler(changeInNetworkEventHandler);
  
    await setupNFTMintedEventListener(nftMintedEventHandler)
    account && setCurrentAccount(account)
    }catch(err) {
      console.error(err);
      setPageError(err.message);
    }
  }


  // remove error in 10 seconds after display!
  useEffect(() => {
    if(pageError){
      setTimeout(() => {
        setPageError(null)
      },10000);
    }
  },[pageError]);

   

  useEffect(() => {
    const setup = async () => {
      try {
        const account = await checkIfWalletIsConnected();
        const chainId = await getCurrentChainId();
        if(chainId!=CHAIN_ID) {
          setShowContent(false);
          return;
        }

        await setupUser(account);
      
      }catch(err) {
        console.error(err)
        setPageError(err.message)
      }

    }

    setLoading(true);
    setup();
    setLoading(false);
  }, [])

  useEffect(() => {
    const setup = async () => {
      if(currentAccount && currentAccount!="") {
        const chainId = await getCurrentChainId();
        if(chainId!=CHAIN_ID) {
          setShowContent(false);
          return;
      }

      const _isWhiteListSaleActive = await isWhiteListSaleActiveService();
      const _isPublicSaleActive = await isPublicSaleActiveService();
      const _isUserWhiteListed = await isUserWhiteListedService();
      const _totalSupply = await getTotalSupply();
      const _mintLimit = await getMintLimit();

      setMintingLimit(parseInt(_mintLimit));
      setTotalSupply(parseInt(_totalSupply))
      setIsWhiteListSaleActive(_isWhiteListSaleActive);
      setIsPublicSaleActive(_isPublicSaleActive);
      setIsUserWhiteListed(_isUserWhiteListed);
      
      getMintedNFTs()
      }
    }
    setup()
    
  },[currentAccount])


  const connectWalletClickHandler =  () => {
    const setup = async () => {
      try {
        const account = await connectWallet();
        await setupUser(account)
      }catch(err) {
        console.error(err)
        setPageError(err.message);
      }
    }
    setLoading(true)
    setup();
    setLoading(false)

  }


  const getMintedNFTs =  () => {
    setFetchLoading(true)
    getMintedTokens()
    .then(tokens => setMintedNFTData(tokens))
    .catch(err => {
      console.error(err);
      setPageError(err.message);
    })
    .finally(() => setFetchLoading(false))
  }


  const whiteListSale = () =>{
    whiteListSaleMint()
    .then((tnxHash) => {
      //This is the success part of sale
      alert(`Welcome to the gang, ${tnxHash}`);

    })
    .catch(err => {
      console.error(err)
      setPageError(err.message);
    })
  }

  const publicSale = async () => {
    try {
      const tnx = await publicSaleMint();
      alert(`Welcome to the gang, ${tnxHash}`);
    }catch(err) {
      console.log(err.message)
      setPageError(err.message);
    }
    
    
  }
 

 
  return (
      <div className='container pt-2'>
        <div className='row'>
          {showContent ? (
            <>
            <div className='col-12 d-flex flex-wrap justify-content-center align-items-center text-center'>
              <a href="https://badmanskullgang.com"><img src={logo} className="logo" alt="BADMANSKULLGANG" /></a>
            </div>
            <div className='col-12 d-flex flex-wrap justify-content-center align-items-center text-center'>
          
            <Spin spinning={loading}>

            {currentAccount!=="" && <MintingStats totalSupply={totalSupply} minted={mintedNFTData.length} mintLimit={mintingLimit}/>}
            {currentAccount=="" ? 
            <Button onClick={connectWalletClickHandler}>Connect Wallet</Button> : 
            isPaused ? <PausedSale/> :
            isPublicSaleActive ? 
            <PublicSale onClickHandler={publicSale}/> :
            isWhiteListSaleActive ?
            <WhiteListSale isWhiteListed={isUserWhiteListed} onClickHandler={whiteListSale}/> :
            <p>Please wait.</p>
            
            }

            <p className="max-limit">Mint Price 0.1 ETH</p>
            <p className="max-limit line-2">Max 4 per wallet</p>
            {/* <p className="max-limit line-2">Max {mintingLimit} per wallet</p> */}

            {/* This is block shows error message */}
            {
              pageError ? (<p className='error-msg'>{pageError}</p>) : null
            }

            {currentAccount!="" ? <p>{currentAccount} is connected</p> : null}

            {/* This is display section */}
            {currentAccount!=="" ? (
              <Spin spinning={mintsFetchLoading}>
                <div className='pt-4'>
                {mintedNFTData.length ? (<div className='row'>{mintedNFTData.map((data,index) => (<div className='col-12' key={index}><MintedToken ipfs={data} /></div>))}</div>)  : (<p class="d-none">Your wallet is empty </p>) }
              </div> 
              </Spin>
            ): null
            }
            </Spin>
            </div>


            </>

            
          )  : (
            <div class="row">

              <div class='col-12 d-flex flex-wrap justify-content-center align-items-center text-center'>
                <img src={logo} className="logo" alt="BADMANSKULLGANG" />
              </div>
              <div class="col-12 text-center p-5 error-msg">
                <p>Looks like you're in different network, please go back to mainnet and refresh.</p>
              </div>
            </div>
          )}
        </div>
      </div>
  )
}

export default App;
