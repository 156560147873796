
import { ethers } from "ethers";
import NFTContract from "../abi/NFTContract.json"
import { CONTRACT_ADDRESS } from "../constants";



//This n
export const checkIfWalletIsConnected = async () => {

    const {ethereum} = window;
    if (!ethereum) {      
        return Promise.reject("Seems like you don't have metamask")
    } 

    const accounts = await ethereum.request({ method: 'eth_accounts' });
    if (accounts.length !== 0) {
      const account = accounts[0];
      return account;
    }
    return Promise.reject("Not accounts found")
}


export const getBalanceOf = async () => {
  try {
    const {ethereum} = window;

    if(ethereum) {
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();

      const connectedContract = new ethers.Contract(CONTRACT_ADDRESS, NFTContract.abi, signer);

      let balance = await connectedContract.getBalanceOf();
    
      return balance; 

    }else {
      return Promise.reject("Please install metamask")
    }
  }catch(err) {
    return Promise.reject(err);
  }
}

export const getMintingFees = async () => {
  try {
    const {ethereum} = window;

    if(ethereum) {
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();

      const connectedContract = new ethers.Contract(CONTRACT_ADDRESS, NFTContract.abi, signer);

      let fees = await connectedContract.mintingFees();
    
      return fees; 

    }else {
      return Promise.reject("Please install metamask")
    }
  }catch(err) {
    return Promise.reject(err)
  }
}
  
export const isUserWhiteListedService = async () => {
  try {
    const {ethereum} = window;

    if(ethereum) {
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();

      const connectedContract = new ethers.Contract(CONTRACT_ADDRESS, NFTContract.abi, signer);
      const accounts = await ethereum.request({ method: "eth_requestAccounts" });
      if(accounts.length == 0) throw new Error("Please install metamask")

      let isWhiteListed = await connectedContract.isWhiteListed();
      return isWhiteListed; 

    }else {
      return Promise.reject("Please install metamask")
    }
  }catch(err) {
    return Promise.reject(err)
  }
}


export const getMintLimit = async () => {
  try {
    const {ethereum} = window;

    if(ethereum) {
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();

      const connectedContract = new ethers.Contract(CONTRACT_ADDRESS, NFTContract.abi, signer);

      let limit = await connectedContract.mintLimit();
    
      return limit; 

    }else {
      return Promise.reject("Please install metamask")
    }
  }catch(err) {
    return Promise.reject(err)
  }
}



export const getTotalSupply = async () => {
  try {
    const {ethereum} = window;

    if(ethereum) {
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();

      const connectedContract = new ethers.Contract(CONTRACT_ADDRESS, NFTContract.abi, signer);

      let totalSupply = await connectedContract.totalSupply();
    
      return totalSupply; 

    }else {
      return Promise.reject("Please install metamask")
    }
  }catch(err) {
    return Promise.reject(err)
  }
}


export const isWhiteListSaleActiveService = async () => {
  try {
    const {ethereum} = window;

    if(ethereum) {
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();

      const connectedContract = new ethers.Contract(CONTRACT_ADDRESS, NFTContract.abi, signer);

      let isWhiteListSaleActive = await connectedContract.isWhiteListSaleActive();
    
      return isWhiteListSaleActive; 

    }else {
      return Promise.reject("Please install metamask")
    }
  }catch(err) {
    return Promise.reject(err)
  }
}

export const isPublicSaleActiveService = async () => {
  try {
    const {ethereum} = window;

    if(ethereum) {
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();

      const connectedContract = new ethers.Contract(CONTRACT_ADDRESS, NFTContract.abi, signer);

      let isPublicSaleActive = await connectedContract.isPublicSaleActive();
    
      return isPublicSaleActive; 

    }else {
      return Promise.reject("Please install metamask")
    }
  }catch(err) {
    return Promise.reject(err)
  }
}


export const connectWallet = async() => {
    try {
        const { ethereum } = window;
    
    if (!ethereum) {
       return;
    }
    const accounts = await ethereum.request({ method: "eth_requestAccounts" });

    return accounts[0]; 
    } catch (error) {
      return Promise.reject(error)
    }
      
}

export const whiteListSaleMint = async (
) => {
    try {
      const {ethereum} = window;

      if(ethereum) {
         const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const connectedContract = new ethers.Contract(CONTRACT_ADDRESS, NFTContract.abi, signer);

        let fees = await connectedContract.mintingFees();
        console.log(fees)
        const transactionObject = {
            value: fees.toLocaleString('fullwide', {useGrouping:false}) //the price of nft in wei
          };
          console.log(transactionObject)
        let nftTxn = await connectedContract.whiteListSale(transactionObject);
      
        await nftTxn.wait();
        
        console.log(`Mined, see transaction: https://rinkeby.etherscan.io/tx/${nftTxn.hash}`);
          return nftTxn.hash

      }else {
        return Promise.reject("Please install metamask")
      }
    }catch(err) {
      return Promise.reject(err)
    }
}


export const publicSaleMint = async (
) => {
  try {
    const {ethereum} = window;

    if(ethereum) {
       const provider = new ethers.providers.Web3Provider(ethereum);
      const signer = provider.getSigner();

      const connectedContract = new ethers.Contract(CONTRACT_ADDRESS, NFTContract.abi, signer);
      let fees = await connectedContract.mintingFees();
      const transactionObject = {
          value: fees.toLocaleString('fullwide', {useGrouping:false}) //the price of nft in wei //the price of nft in wei
        };
      let nftTxn = await connectedContract.publicSale(transactionObject);
    
      await nftTxn.wait();
      
      console.log(`Mined, see transaction: https://rinkeby.etherscan.io/tx/${nftTxn.hash}`);
        return nftTxn.hash;

    }else {
      return Promise.reject("Please install metamask")
    }
  }catch(err) {
    return Promise.reject(err)
  }
}


export const getMintedTokens =async () => {
    try {
    const {ethereum} = window;

    if(ethereum) {
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();

      const connectedContract = new ethers.Contract(CONTRACT_ADDRESS, NFTContract.abi, signer);

      let userBalance = await connectedContract.getBalanceOf();
    
      const tokenURIs = []
      for(let i=0; i<userBalance;i++) {
        const tokenId = await connectedContract.tokenOfMsgSenderByIndex(i);
        const tokenURI = await connectedContract.tokenURI(tokenId);
        tokenURIs.push(tokenURI)
      }
      return tokenURIs


    }else {
      return Promise.reject("Please install metamask")
    }
  }catch(err) {
    return Promise.reject(err)
  }
}




export const setupNFTMintedEventListener = async (callback) => {
    try {
        const { ethereum } = window;
  
        if (ethereum) {
          const provider = new ethers.providers.Web3Provider(ethereum);
          const signer = provider.getSigner();
          const connectedContract = new ethers.Contract(CONTRACT_ADDRESS, NFTContract.abi, signer);
          connectedContract.on("NFTMintedEvent", (from, tokenId) => {
            callback(from, tokenId.toNumber())
          });
    
        } else {
          return Promise.reject("Ethereum object doesn't exist!");
        }
      } catch (error) {
        return Promise.reject(error)
      }
}


export const getCurrentChainId = async () => {
  try {
    const { ethereum } = window;
  
        if (ethereum) {
         return ethereum.chainId
    
        } else {
          return Promise.reject("Ethereum object doesn't exist!");
        }
  }catch(err) {
    return Promise.reject(err)
  }
}

export const setupNetworkChangeHandler = (callback) => {
  try {
    const { ethereum } = window;

    if (ethereum) {
      ethereum.on('chainChanged', function(networkId){
        callback(networkId)
      });
    } else {
      return Promise.reject("Ethereum object doesn't exist!");
    }
  } catch (error) {
    return Promise.reject(error)
  }
}