import React from 'react';

const WhiteListSale = (props) => {
  return <div>
      {
          props.isWhiteListed ? 
          <button class='btn btn-primary' onClick={props.onClickHandler}>Mint</button> : 
          <h5 class="no-whitelist">You are not whitelisted.</h5>
      }
  </div>;
};

export default WhiteListSale;
