import React from 'react';

const MintingStats = (props) => {
  return <div>
      {/* <div><h4>26 / 3,333</h4></div> */}
      {/* <div><h4>{props.totalSupply} / 3,333</h4></div> */}
      {/* <p>Max {props.mintLimit} per wallet. {props.minted} / </p> */}
      {/* <p>Max {props.mintLimit} per wallet</p> */}
      </div>;
};

export default MintingStats;
