import React ,{useState,useEffect} from 'react';

const MintedToken = (props) => {

  const [nftImage, setNFTImage] = useState("");
  
  const [nftAttribute,setNFTAttributes ] = useState([]);

  const [nftName, setNFTName] = useState("");



  const getHTTPLinkeFromIPFS = (value) => {
    const filePath = value.split("//").pop();
    return "https://ipfs.io/ipfs/"+ filePath
  }

  useEffect(() => {
    const fetcNFTMetata = async () => {

      if(props.ipfs) {
        const resp = await fetch(getHTTPLinkeFromIPFS(props.ipfs));
      
        const json = await resp.json();

        setNFTImage(getHTTPLinkeFromIPFS(json.image));

        setNFTAttributes(json.attributes);
        setNFTName(json.name);


      }
    }
    fetcNFTMetata()
    
  },[])



  return (
    nftImage ?( <div>
      <img class="minted" src={nftImage} height={200} />
      <h5>{nftName}</h5>
      {nftAttribute.map((att,idx) => {
        return <p class="trait" key={idx}>{att.value}</p>
      })}
    </div>) : null
  )
}



export default MintedToken